import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Col, Row, Grid } from '@sumup/circuit-ui/legacy';
import { useInView } from 'react-intersection-observer';

import ShowcaseVideo from './components/ShowcaseVideo';
import ShowcaseEmbeddedVideo from './components/ShowcaseEmbeddedVideo';
import ShowcaseImage from './components/ShowcaseImage';
import Badge from './components/Badge';
import { getBadgeAlignments } from './CaptionedShowcaseService';

import LegacyButtonGroup from '~/shared/components/LegacyButtonGroup';
import RichText from '~/shared/components/RichText';
import * as captionedShowcase from '~/shared/components/RichText/configs/captioned-showcase';
import { mapHeadingsContent } from '~/shared/components/RichText/util';
import { ALIGNMENT, COMBINED_ALIGNMENTS, VIEWPORTS } from '~/shared/constants';
import dataSelector from '~/shared/util/data-selector';
import {
  richTextPropType,
  mediaPropType,
} from '~/shared/util/shared-prop-types';
import getHeadingTag from '~/shared/util/get-heading-tag';
// eslint-disable-next-line max-len
import { getVariationComponent } from '~/shared/services/optimizely/OptimizelyVariationsService';
import useOptimizelyData from '~/shared/services/optimizely/use-optimizely-data';
import useViewportName from '~/shared/hooks/use-viewport-name';

const DATA_SELECTOR = 'caption_showcase';
const SUPPORTED_MEDIA_TYPES = {
  VIDEO: 'video',
  IMAGE: 'image',
  EMBEDDED_VIDEO: 'showcaseEmbeddedVideo',
  GENERIC_IMAGE: 'genericImage',
};

const MEDIA_TYPE_TO_COMPONENT = {
  [SUPPORTED_MEDIA_TYPES.GENERIC_IMAGE]: ShowcaseImage,
  [SUPPORTED_MEDIA_TYPES.VIDEO]: ShowcaseVideo,
  [SUPPORTED_MEDIA_TYPES.EMBEDDED_VIDEO]: ShowcaseEmbeddedVideo,
};

const styledRowStyles = ({ theme, alignment }) => css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${theme.mq.kilo} {
    flex-direction: ${alignment === ALIGNMENT.RIGHT ? 'row' : 'row-reverse'};
  }
`;

const StyledRow = styled(Row)(styledRowStyles);

const mediaColStyles = css`
  margin: 0 auto;
`;

const MediaCol = styled(Col)(mediaColStyles);

const textColStyles = ({ theme }) => css`
  margin-top: 0;

  ${theme.mq.untilKilo} {
    margin-top: ${theme.spacings.giga};
  }
`;

const StyledCol = styled(Col)(textColStyles);

const buttonGroupStyles = ({ theme }) => css`
  ${theme.mq.kilo} {
    flex-wrap: nowrap;
  }
`;

const StyledButtonGroup = styled(LegacyButtonGroup)(buttonGroupStyles);

const renderMedia = ({ contentType: mediaContentType, data }, imageUrl) => {
  const MediaComponent = MEDIA_TYPE_TO_COMPONENT[mediaContentType];

  return MediaComponent ? (
    <MediaComponent {...data} imageUrl={imageUrl} />
  ) : null;
};

/**
 * CaptionedShowcase component to highlight and showcase information.
 */
function CaptionedShowcase({
  media: originalMedia = {},
  alignment = ALIGNMENT.LEFT,
  content,
  className,
  contentType,
  name,
  id,
  badge: originalBadge,
  badgeDesktopAlignment,
  badgeTabletAlignment,
  badgeMobileAlignment,
  index,
  imageUrl,
  ...buttonProps
}) {
  const viewportName = useViewportName();
  const { experiments } = useOptimizelyData();
  const media = getVariationComponent(originalMedia, experiments);
  const badge = getVariationComponent(originalBadge, experiments);

  const viewports = {
    [VIEWPORTS.MOBILE]: badgeMobileAlignment,
    [VIEWPORTS.TABLET]: badgeTabletAlignment,
    [VIEWPORTS.DESKTOP]: badgeDesktopAlignment,
  };

  const [badgeHorizontalAlignment, badgeVerticalAlignment] = getBadgeAlignments(
    viewports[viewportName] || COMBINED_ALIGNMENTS.TOP_LEFT,
  );

  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });

  const as = getHeadingTag(index);

  return (
    <Grid
      data-selector={dataSelector('section', DATA_SELECTOR)}
      data-elbcontext="component:captioned_showcase"
    >
      <StyledRow alignment={alignment} className={className}>
        <MediaCol
          ref={ref}
          data-selector={dataSelector('media-col', DATA_SELECTOR)}
          span={{ default: 12, mega: 6 }}
        >
          {badge && (
            <Badge
              inView={inView}
              alignmentHorizontal={badgeHorizontalAlignment}
              alignmentVertical={badgeVerticalAlignment}
              badge={badge}
            />
          )}
          {renderMedia(media, imageUrl)}
        </MediaCol>
        <StyledCol
          span={{ default: 12, mega: 6 }}
          data-selector={dataSelector('content-col', DATA_SELECTOR)}
        >
          <RichText
            richText={mapHeadingsContent(content, as)}
            renderNode={captionedShowcase.createRenderNode({
              contentType,
              contentEntryName: name,
              contentEntryId: id,
            })}
            renderMark={captionedShowcase.renderMark}
          />
          <StyledButtonGroup
            buttonDataSelector={dataSelector('button_primary', DATA_SELECTOR)}
            secondaryButtonDataSelector={dataSelector(
              'button_secondary',
              DATA_SELECTOR,
            )}
            align={LegacyButtonGroup.LEFT}
            trackingContentEntry={{
              contentType,
              contentEntryName: name,
              contentEntryId: id,
            }}
            {...buttonProps}
          />
        </StyledCol>
      </StyledRow>
    </Grid>
  );
}

CaptionedShowcase.propTypes = {
  /**
   * Enables custom styles
   */
  className: PropTypes.string,
  /**
   * Rich text content
   */
  content: richTextPropType,
  /**
   * Label for the primary button
   */
  buttonLabel: PropTypes.string,
  /**
   * Url for the primary button
   */
  buttonUrl: PropTypes.string,
  /**
   * Tracking id for the primary button
   */
  buttonTrackingId: PropTypes.string,
  /*
   * Optimizely Full Stack click events for the primary button
   */
  buttonOptimizelyFullStackClickEvents: PropTypes.arrayOf(PropTypes.string),
  /**
   * Label for the secondary button
   */
  secondaryButtonLabel: PropTypes.string,
  /**
   * Url for the secondary button
   */
  secondaryButtonUrl: PropTypes.string,
  /**
   * Tracking id for the secondary button
   */
  secondaryButtonTrackingId: PropTypes.string,
  /*
   * Optimizely Full Stack click events for the secondary button
   */
  secondaryButtonOptimizelyFullStackClickEvents: PropTypes.arrayOf(
    PropTypes.string,
  ),
  /**
   * Displayed media (image, video, etc.)
   */
  media: mediaPropType,
  /**
   * The alignment determines the order of media and content on wide viewports
   */
  alignment: PropTypes.oneOf([ALIGNMENT.LEFT, ALIGNMENT.RIGHT]),
  contentType: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
  imageUrl: PropTypes.string,
  badge: PropTypes.object,
  badgeDesktopAlignment: PropTypes.oneOf(Object.values(COMBINED_ALIGNMENTS)),
  badgeTabletAlignment: PropTypes.oneOf(Object.values(COMBINED_ALIGNMENTS)),
  badgeMobileAlignment: PropTypes.oneOf(Object.values(COMBINED_ALIGNMENTS)),
  index: PropTypes.number,
};

/**
 * @component
 */
export default CaptionedShowcase;
