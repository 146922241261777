import React from 'react';

import BadgeContainer from '../BadgeContainer';
import { badgePropTypes } from '../../shared-prop-types';

import { FeeBadge } from '~/shared/components/Badges';
import { ALIGNMENT_VERTICAL, ALIGNMENT_HORIZONTAL } from '~/shared/constants';

function FeeBadgeExtended({
  alignmentHorizontal = ALIGNMENT_HORIZONTAL.TOP,
  alignmentVertical = ALIGNMENT_VERTICAL.LEFT,
  badge = {},
  inView,
}) {
  return (
    <BadgeContainer
      inView={inView}
      alignmentHorizontal={alignmentHorizontal}
      alignmentVertical={alignmentVertical}
      renderStyledBadge={(styleProps) => (
        <FeeBadge {...badge} {...styleProps} />
      )}
    />
  );
}

FeeBadgeExtended.propTypes = badgePropTypes;

export default FeeBadgeExtended;
