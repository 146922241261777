import React from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

import { PRICE_FORMATS } from '~/shared/constants/price';

const originalPriceStyles = ({ color, textSize, theme }) => css`
  color: ${color};
  display: inline-block;
  position: relative;

  font-size: ${textSize}px;
  line-height: 1;
  margin: ${theme.spacings.bit} ${theme.spacings.byte};

  ::before {
    position: absolute;
    content: '';
    left: 0;
    top: 50%;
    right: 0;
    border-top: 1px solid;
    border-color: inherit;

    -moz-transform: rotate(-10deg);
    -o-transform: rotate(-10deg);
    transform: rotate(-10deg);
  }
`;

const priceColor = '#666';

export const OriginalPriceSized = styled('span')(originalPriceStyles);

function OriginalPrice({
  format = PRICE_FORMATS.SIZE_32,
  fullPrice,
  price,
  installments,
}) {
  return (
    <OriginalPriceSized color={priceColor} textSize={format.sizes.old_price}>
      {installments ? `${installments}x ${price}` : fullPrice || price}
    </OriginalPriceSized>
  );
}

OriginalPrice.propTypes = {
  format: PropTypes.object,
  price: PropTypes.string,
  fullPrice: PropTypes.string,
  installments: PropTypes.number,
};

export default OriginalPrice;
