import React from 'react';

import BadgeContainer from '../BadgeContainer';
import { badgePropTypes } from '../../shared-prop-types';

import { TextBadge } from '~/shared/components/Badges';
import { ALIGNMENT_VERTICAL, ALIGNMENT_HORIZONTAL } from '~/shared/constants';

function TextBadgeExtended({
  alignmentHorizontal = ALIGNMENT_HORIZONTAL.TOP,
  alignmentVertical = ALIGNMENT_VERTICAL.LEFT,
  badge = {},
  inView,
}) {
  if (!badge.content) {
    return null;
  }

  return (
    <BadgeContainer
      inView={inView}
      alignmentHorizontal={alignmentHorizontal}
      alignmentVertical={alignmentVertical}
      renderStyledBadge={(styleProps) => (
        <TextBadge {...badge} {...styleProps} />
      )}
    />
  );
}

TextBadgeExtended.propTypes = badgePropTypes;

export default TextBadgeExtended;
