import React from 'react';

import FeeBadgeExtended from './components/FeeBadgeExtended';
import PricingBadgeExtended from './components/PricingBadgeExtended';
import TextBadgeExtended from './components/TextBadgeExtended';
import { badgePropTypes } from './shared-prop-types';

import {
  ALIGNMENT_VERTICAL,
  ALIGNMENT_HORIZONTAL,
  BADGE_TYPES,
} from '~/shared/constants';

function Badge({
  alignmentHorizontal = ALIGNMENT_HORIZONTAL.TOP,
  alignmentVertical = ALIGNMENT_VERTICAL.LEFT,
  badge = {},
  inView,
}) {
  if (badge.contentType === BADGE_TYPES.PRICING_BADGE) {
    return (
      <PricingBadgeExtended
        alignmentHorizontal={alignmentHorizontal}
        alignmentVertical={alignmentVertical}
        inView={inView}
        badge={badge}
      />
    );
  }

  if (badge.contentType === BADGE_TYPES.FEE_BADGE) {
    return (
      <FeeBadgeExtended
        alignmentHorizontal={alignmentHorizontal}
        alignmentVertical={alignmentVertical}
        inView={inView}
        badge={badge}
      />
    );
  }

  if (badge.contentType === BADGE_TYPES.TEXT_BADGE) {
    return (
      <TextBadgeExtended
        alignmentHorizontal={alignmentHorizontal}
        alignmentVertical={alignmentVertical}
        inView={inView}
        badge={badge}
      />
    );
  }

  return null;
}

Badge.propTypes = badgePropTypes;

export default Badge;
