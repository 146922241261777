import { ALIGNMENT_VERTICAL, ALIGNMENT } from '~/shared/constants';

export function transformVerticalAlignment(verticalAlignment) {
  return verticalAlignment === ALIGNMENT.CENTER
    ? ALIGNMENT_VERTICAL.MIDDLE
    : verticalAlignment;
}

export function getBadgeAlignments(badgeAlignment) {
  if (!badgeAlignment) {
    return [];
  }

  const [badgeHorizontalAlignment, badgeVerticalAlignment] =
    badgeAlignment.split(' ');

  return [
    badgeHorizontalAlignment.toLowerCase(),
    transformVerticalAlignment(badgeVerticalAlignment).toLowerCase(),
  ];
}
