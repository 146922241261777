import React, { useContext } from 'react';
import isEmpty from 'lodash/fp/isEmpty';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import BadgeContainer from '../BadgeContainer';
import OriginalPrice from '../OriginalPrice';
import { badgePropTypes } from '../../shared-prop-types';

import ProductsContext from '~/shared/providers/ProductsContext';
import { PricingBadge } from '~/shared/components/Badges';
import { ALIGNMENT_VERTICAL, ALIGNMENT_HORIZONTAL } from '~/shared/constants';

const fullPriceLayoutStyles = {
  [ALIGNMENT_VERTICAL.LEFT]: ({ theme }) => css`
    left: ${theme.spacings.byte};

    ${theme.mq.kilo} {
      left: ${theme.spacings.mega};
    }
  `,
  [ALIGNMENT_VERTICAL.MIDDLE]: () => css`
    left: 50%;
    transform: translateX(-50%);
  `,
  [ALIGNMENT_VERTICAL.RIGHT]: ({ theme }) => css`
    right: ${theme.spacings.byte};

    ${theme.mq.kilo} {
      right: ${theme.spacings.mega};
    }
  `,
};

const fullPriceWrapperBaseStyles = ({ theme }) => css`
  display: inline-block;
  position: absolute;
  padding-top: ${theme.spacings.kilo};
  bottom: -${theme.spacings.giga};
  z-index: -1;
  background: rgba(255, 255, 255, 0.59);
  backdrop-filter: blur(20px);

  border-bottom-left-radius: ${theme.borderRadius.mega};
  border-bottom-right-radius: ${theme.borderRadius.mega};
`;

const getFullPriceWrapperStyles = ({ alignmentVertical, theme }) => css`
  ${fullPriceLayoutStyles[alignmentVertical]({ theme })}
`;

const StyledFullPriceWrapper = styled('div')(
  fullPriceWrapperBaseStyles,
  getFullPriceWrapperStyles,
);

function PricingBadgeExtended({
  alignmentHorizontal = ALIGNMENT_HORIZONTAL.TOP,
  alignmentVertical = ALIGNMENT_VERTICAL.LEFT,
  badge = {},
  inView,
}) {
  const { products = {} } = useContext(ProductsContext);
  const product = products[badge.product?.productId];

  if (isEmpty(product) || badge.isHidden) {
    return null;
  }

  const { fullPrice, hasPromo, installments, price } = product;

  return (
    <BadgeContainer
      inView={inView}
      alignmentHorizontal={alignmentHorizontal}
      alignmentVertical={alignmentVertical}
      renderStyledBadge={(styleProps) => (
        <PricingBadge {...badge} {...styleProps} />
      )}
    >
      {hasPromo && (
        <StyledFullPriceWrapper
          alignmentHorizontal={alignmentHorizontal}
          alignmentVertical={alignmentVertical}
        >
          <OriginalPrice
            fullPrice={fullPrice}
            installments={installments}
            price={price}
          />
        </StyledFullPriceWrapper>
      )}
    </BadgeContainer>
  );
}

PricingBadgeExtended.propTypes = badgePropTypes;

export default PricingBadgeExtended;
