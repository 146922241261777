import values from 'lodash/fp/values';
import PropTypes from 'prop-types';

import { ALIGNMENT_VERTICAL, ALIGNMENT_HORIZONTAL } from '~/shared/constants';

// eslint-disable-next-line import/prefer-default-export
export const badgePropTypes = {
  alignmentHorizontal: PropTypes.oneOf(values(ALIGNMENT_HORIZONTAL)),
  alignmentVertical: PropTypes.oneOf(values(ALIGNMENT_VERTICAL)),
  /**
   * Product or Fee Badge in Contentful
   */
  badge: PropTypes.object,
  inView: PropTypes.bool,
};
