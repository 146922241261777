import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import dataSelector from '~/shared/util/data-selector';
import PosterVideo from '~/shared/components/PosterVideo';

const videoContainerStyles = ({ theme }) => css`
  max-width: 100%;
  margin: 0 auto;
  margin-bottom: ${theme.spacings.mega};
  padding-top: ${theme.spacings.giga};

  ${theme.mq.mega} {
    margin-bottom: ${theme.spacings.mega};
  }
`;

const VideoContainer = styled('figure')(videoContainerStyles);

/**
 * Video Component
 */
const ShowcaseVideo = ({
  src,
  title,
  aspectRatio,
  posterImage,
  openInModal,
  trackingId,
  ...rest
}) => {
  if (!src) {
    return null;
  }

  return (
    <VideoContainer
      data-selector={dataSelector('video-container', 'caption_showcase')}
    >
      <PosterVideo
        video={{
          ...rest,
          src,
          showRelatedVideos: false,
          removeBranding: false,
          hideControls: false,
          title,
          aspectRatio,
          posterImage,
          trackingId,
        }}
        aspectRatio={aspectRatio}
        posterImage={posterImage}
        alignToParent={false}
        openInModal={openInModal}
      />
    </VideoContainer>
  );
};

ShowcaseVideo.propTypes = {
  src: PropTypes.string.isRequired,
  title: PropTypes.string,
  aspectRatio: PropTypes.number,
  posterImage: PropTypes.object,
  openInModal: PropTypes.bool,
  /**
   * An id used for tracking video plays
   */
  trackingId: PropTypes.string,
};

/**
 * @component
 */
export default ShowcaseVideo;
