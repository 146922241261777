import React from 'react';
import values from 'lodash/fp/values';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { ALIGNMENT_VERTICAL, ALIGNMENT_HORIZONTAL } from '~/shared/constants';
import { childrenPropType } from '~/shared/util/shared-prop-types';

/* eslint-disable max-len, prettier/prettier */
const topLeft = `${ALIGNMENT_HORIZONTAL.TOP}_${ALIGNMENT_VERTICAL.LEFT}`;
const topMiddle = `${ALIGNMENT_HORIZONTAL.TOP}_${ALIGNMENT_VERTICAL.MIDDLE}`;
const topRight = `${ALIGNMENT_HORIZONTAL.TOP}_${ALIGNMENT_VERTICAL.RIGHT}`;

const middleLeft = `${ALIGNMENT_HORIZONTAL.MIDDLE}_${ALIGNMENT_VERTICAL.LEFT}`;
const middleMiddle = `${ALIGNMENT_HORIZONTAL.MIDDLE}_${ALIGNMENT_VERTICAL.MIDDLE}`;
const middleRight = `${ALIGNMENT_HORIZONTAL.MIDDLE}_${ALIGNMENT_VERTICAL.RIGHT}`;

const bottomLeft = `${ALIGNMENT_HORIZONTAL.BOTTOM}_${ALIGNMENT_VERTICAL.LEFT}`;
const bottomMiddle = `${ALIGNMENT_HORIZONTAL.BOTTOM}_${ALIGNMENT_VERTICAL.MIDDLE}`;
const bottomRight = `${ALIGNMENT_HORIZONTAL.BOTTOM}_${ALIGNMENT_VERTICAL.RIGHT}`;
/* eslint-enable max-len, prettier/prettier */

const layoutStyles = {
  [topLeft]: ({ theme }) => css`
    top: ${theme.spacings.mega};
    left: -${theme.spacings.byte};
  `,
  [topMiddle]: ({ theme }) => css`
    top: -${theme.spacings.giga};
    left: 50%;
    transform: translateX(-50%);
  `,
  [topRight]: ({ theme }) => css`
    top: ${theme.spacings.mega};
    right: -${theme.spacings.byte};
  `,
  [middleLeft]: () => css`
    top: 50%;
    left: -10px;
    transform: translateY(-50%);
  `,
  [middleMiddle]: () => css`
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  `,
  [middleRight]: () => css`
    top: 50%;
    right: -10px;
    transform: translateY(-50%);
  `,
  [bottomLeft]: ({ theme }) => css`
    bottom: ${theme.spacings.tera};
    left: -${theme.spacings.byte};

    ${theme.mq.kilo} {
      bottom: ${theme.spacings.peta};
    }
  `,
  [bottomMiddle]: ({ theme }) => css`
    bottom: -${theme.spacings.giga};
    left: 50%;
    transform: translateX(-50%);
  `,
  [bottomRight]: ({ theme }) => css`
    bottom: ${theme.spacings.tera};
    right: -${theme.spacings.byte};

    ${theme.mq.kilo} {
      bottom: ${theme.spacings.peta};
    }
  `,
};

const animationStyles = {
  [ALIGNMENT_VERTICAL.LEFT]: ({ theme }) => css`
    transform: translateX(-100%);

    ${theme.mq.kilo} {
      transform: translateX(0%);
    }
  `,
  [ALIGNMENT_VERTICAL.RIGHT]: ({ theme }) => css`
    transform: translateX(100%);

    ${theme.mq.kilo} {
      transform: translateX(0%);
    }
  `,
};

const wrapperBaseStyles = ({ theme }) => css`
  label: badge;
  position: absolute;
  z-index: ${theme.zIndex.badge};
`;

const getWrapperStyles = ({
  alignmentVertical,
  alignmentHorizontal,
  theme,
}) => {
  const alignment = `${alignmentHorizontal}_${alignmentVertical}`;

  return css`
    ${layoutStyles[alignment]({ theme })}
    ${animationStyles[alignmentVertical] &&
    animationStyles[alignmentVertical]({ theme })}
  `;
};

const animateOnVisibleStyles = ({ visible, alignmentVertical }) =>
  visible &&
  alignmentVertical !== ALIGNMENT_VERTICAL.MIDDLE &&
  css`
    transform: translate(0%, 0);
    transition: 0.5s ease-in-out;
  `;

const Wrapper = styled('div')(
  wrapperBaseStyles,
  getWrapperStyles,
  animateOnVisibleStyles,
);

const badgeRoundedCornerStyles = {
  [topLeft]: ({ theme }) => css`
    border-top-right-radius: ${theme.borderRadius.exa};
    border-bottom-right-radius: ${theme.borderRadius.exa};

    ${theme.mq.kilo} {
      border-top-right-radius: ${theme.borderRadius.exa};
      border-bottom-right-radius: ${theme.borderRadius.exa};
      border-top-left-radius: ${theme.borderRadius.exa};
      border-bottom-left-radius: ${theme.borderRadius.exa};
    }
  `,
  [topMiddle]: ({ theme }) => css`
    border-top-right-radius: ${theme.borderRadius.exa};
    border-bottom-right-radius: ${theme.borderRadius.exa};
    border-top-left-radius: ${theme.borderRadius.exa};
    border-bottom-left-radius: ${theme.borderRadius.exa};
  `,
  [topRight]: ({ theme }) => css`
    border-top-left-radius: ${theme.borderRadius.exa};
    border-bottom-left-radius: ${theme.borderRadius.exa};

    ${theme.mq.kilo} {
      border-top-right-radius: ${theme.borderRadius.exa};
      border-bottom-right-radius: ${theme.borderRadius.exa};
      border-top-left-radius: ${theme.borderRadius.exa};
      border-bottom-left-radius: ${theme.borderRadius.exa};
    }
  `,
  [middleLeft]: ({ theme }) => css`
    border-top-right-radius: ${theme.borderRadius.exa};
    border-bottom-right-radius: ${theme.borderRadius.exa};

    ${theme.mq.kilo} {
      border-top-right-radius: ${theme.borderRadius.exa};
      border-bottom-right-radius: ${theme.borderRadius.exa};
      border-top-left-radius: ${theme.borderRadius.exa};
      border-bottom-left-radius: ${theme.borderRadius.exa};
    }
  `,
  [middleMiddle]: ({ theme }) => css`
    border-top-right-radius: ${theme.borderRadius.exa};
    border-bottom-right-radius: ${theme.borderRadius.exa};
    border-top-left-radius: ${theme.borderRadius.exa};
    border-bottom-left-radius: ${theme.borderRadius.exa};
  `,
  [middleRight]: ({ theme }) => css`
    border-top-left-radius: ${theme.borderRadius.exa};
    border-bottom-left-radius: ${theme.borderRadius.exa};

    ${theme.mq.kilo} {
      border-top-right-radius: ${theme.borderRadius.exa};
      border-bottom-right-radius: ${theme.borderRadius.exa};
      border-top-left-radius: ${theme.borderRadius.exa};
      border-bottom-left-radius: ${theme.borderRadius.exa};
    }
  `,
  [bottomLeft]: ({ theme }) => css`
    border-top-right-radius: ${theme.borderRadius.exa};
    border-bottom-right-radius: ${theme.borderRadius.exa};

    ${theme.mq.kilo} {
      border-top-right-radius: ${theme.borderRadius.exa};
      border-bottom-right-radius: ${theme.borderRadius.exa};
      border-top-left-radius: ${theme.borderRadius.exa};
      border-bottom-left-radius: ${theme.borderRadius.exa};
    }
  `,
  [bottomMiddle]: ({ theme }) => css`
    border-top-right-radius: ${theme.borderRadius.exa};
    border-bottom-right-radius: ${theme.borderRadius.exa};
    border-top-left-radius: ${theme.borderRadius.exa};
    border-bottom-left-radius: ${theme.borderRadius.exa};
  `,
  [bottomRight]: ({ theme }) => css`
    border-top-left-radius: ${theme.borderRadius.exa};
    border-bottom-left-radius: ${theme.borderRadius.exa};

    ${theme.mq.kilo} {
      border-top-right-radius: ${theme.borderRadius.exa};
      border-bottom-right-radius: ${theme.borderRadius.exa};
      border-top-left-radius: ${theme.borderRadius.exa};
      border-bottom-left-radius: ${theme.borderRadius.exa};
    }
  `,
};

const getBadgeStyles = (alignmentHorizontal, alignmentVertical) => (theme) => {
  const alignment = `${alignmentHorizontal}_${alignmentVertical}`;

  return css`
    ${badgeRoundedCornerStyles[alignment]({ theme })}
  `;
};

function BadgeContainer({
  alignmentHorizontal = ALIGNMENT_HORIZONTAL.TOP,
  alignmentVertical = ALIGNMENT_VERTICAL.LEFT,
  inView,
  children,
  renderStyledBadge: RenderStyledBadge,
}) {
  return (
    <Wrapper
      visible={inView}
      alignmentHorizontal={alignmentHorizontal}
      alignmentVertical={alignmentVertical}
    >
      <RenderStyledBadge
        css={getBadgeStyles(alignmentHorizontal, alignmentVertical)}
      />
      {children}
    </Wrapper>
  );
}

BadgeContainer.propTypes = {
  alignmentHorizontal: PropTypes.oneOf(values(ALIGNMENT_HORIZONTAL)),
  alignmentVertical: PropTypes.oneOf(values(ALIGNMENT_VERTICAL)),
  inView: PropTypes.bool,
  children: childrenPropType,
  renderStyledBadge: PropTypes.func,
};

export default BadgeContainer;
